export default {
  created(){
    document.addEventListener('keyup',this._resolveKey)
  },
  beforeDestroy(){
    document.removeEventListener('keyup',this._resolveKey)
  },
  methods:{
    _resolveKey(e){
      if(e.key === "Escape"){
        this.close()
      }
    }
  }
}