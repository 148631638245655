<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Контроль XSD схемы</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <p>{{ params.text }}</p>
        <v-btn
          color="red"
          class="white--text"
          @click="sendToSupport"
        >
          Отправить в техподдержку
        </v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
export default {
  mixins:[closeOnEscape],
  inject:['rootNamespace'],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    params:{
      required:true,
      type:Object
    }
  },
  methods:{
    sendToSupport(){
      const id = this.$route.params.id
      this.$store.dispatch(`${this.rootNamespace}/xsdErrorOnEmail`, id).then(() => {
        this.$info('Сообщение отправлено в техподдержку')
        this.close()
      }).catch(() => this.$error())
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
